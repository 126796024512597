<template>
  <!-- 左侧菜单栏循环嵌套 -->
  <div>
    <template v-for="menu in this.menuData">
      <!-- {{ indexFn(`/home/${urlFn(menu.url2).split("?")[0]}`, menu) }} -->
      <!-- {{ `/home/${urlFn(menu.url2).split("?")[0]}` }} -->
      <!-- {{ menu.id + menu.sort }} -->
      <el-submenu
        popper-class="amenu"
        :key="menu.id"
        :index="indexFn(`/home/${urlFn(menu.url2).split('?')[0]}`, menu)"
        :route="{ path: `/home/${urlFn(menu.url2)}` }"
        v-if="menu.list"
      >
        <template slot="title">
          <!-- <i class="el-icon-share"></i> -->
          <span slot="title">{{ menu.title }}</span>
        </template>
        <menu-tree :menuData="menu.list"></menu-tree>
      </el-submenu>
      <el-menu-item
        :key="menu.id"
        :index="indexFn(`/home/${urlFn(menu.url2).split('?')[0]}`, menu)"
        :route="{ path: `/home/${urlFn(menu.url2)}` }"
        v-else
      >
        <!-- <i class="el-icon-share"></i> -->
        <span slot="title">{{ menu.title }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
// 解决首页左侧菜单栏折叠文字不隐藏的bug
import { Fragment } from "vue-fragment";
export default {
  components: { Fragment },
  props: ["menuData"],
  name: "MenuTree",
  computed: {
    urlFn() {
      return (data) => {
        // 是否含有中文 如果含有中文 以最后一个/分割去除后面的
        // 例:info/folder/fid=149/fname=公司公告 -> info/folder/fid=149
        if (/.*[\u4e00-\u9fa5]+.*$/.test(data)) {
          let arr = data.split("/");

          // fname=公司公告
          let tag = arr[3];

          arr.pop();
          data = arr.join("/") + "?" + tag;

          // console.log(data);
        }

        return data;
      };
    },
    indexFn() {
      return (data, menu) => {
        if (data.indexOf("/#") != -1 || data == "/home/") {
          // 处理一样的index导致点击菜单项会联动打开
          data = data + menu.id + menu.sort;
          // console.log(data, menu, menu.title, data + menu.id + menu.sort);
        }

        return data;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-submenu__title,
.el-submenu .el-menu-item {
  padding-right: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 46px;
  line-height: 46px;
}

.el-submenu .el-menu-item {
  padding-right: 20px;
}

// 设置点击高亮的背景色
.is-active {
  background-color: #409eff !important;
}
</style>
<style lang="scss">
// .amenu {
//   // 弹出的二级菜单 但是这样三级菜单就有问题了
//   .el-menu--popup {
//     overflow: auto !important;
//     max-height: 100vh !important;
//   }
// }
</style>
