<template>
  <!-- 首页/便签 -->
  <div>
    <el-dialog
      title="便签"
      width="30%"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
    >
      <el-input
        @change="changeNote"
        type="textarea"
        v-model="note"
        placeholder="请输入内容"
        :autosize="{ minRows: 6, maxRows: 10 }"
      ></el-input>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogTableVisible: false,
      note: "",
    };
  },
  methods: {
    getList() {
      if (window.localStorage.getItem("noteBook")) {
        this.note = window.localStorage.getItem("noteBook");
      } else {
        this.note =
          "便签中的内容会存储在本地，这样即便你关掉了浏览器，在下次打开时，依然会读取到上一次的记录。是个非常小巧实用的本地备忘录";
        window.localStorage.setItem("noteBook", this.note);
      }

      this.dialogTableVisible = true;
    },
    changeNote(e) {
      // console.log(e, this.note);
      window.localStorage.setItem("noteBook", this.note);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #20222a;
  .el-dialog__title {
    color: #fff;
  }
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #ddd !important;
}
// Dialog悬停样式
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #aaa !important;
}
::v-deep .el-dialog__body {
  max-height: 60vh;
  overflow: auto;
  padding: 0;

  .el-textarea__inner {
    border-width: 0;
  }
}
</style>
