// 集团公司接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const JtgsApi = {
  // 集团组织结构
  groupStructTree() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Groupdeptapi/getgdpulist`);
  },
  // 集团组织结构 -> 人员信息
  groupStruct({ id, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/Groupdeptapi/getdeptuser&id=${id}&page=${page}&limit=${limit}`,
      "",
      false
    );
  },
  // 集团信息->选择单位树
  getCompanyTree() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/groupdeptapi/getcompanytreeb`
    );
  },
  // 集团信息->选择单位树->点击上方单位树-人员信息
  getAllInfo(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/systemapi/getallinfo&companyid=${params.id}`
    );
  },
  // 集团信息->选择单位树->点击上方单位树-人员信息
  getSignInfo(params) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/groupdeptapi/${params.url_type}&companyid=${params.companyid}`
    );
  },
  // 集团证书档案-查询
  getUserListCid(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/groupdeptapi/getuserlist`,
      params,
      false
    );
  },
  // 集团合同档案-查询
  getExpiryListCid(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/groupdeptapi/getexpirylist`,
      params,
      false
    );
  },
  // 集团合同档案 -> 导出人员getexpirylist/导出合同getcontractlist
  contractExportType(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/groupdeptapi/${params.url_type}`,
      params,
      true,
      "blob"
    );
  },
};

export default JtgsApi;
