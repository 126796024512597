// 审批接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const SpApi = {
  // getfixed固定事项 getudf自定义事项
  approvalType(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/FlowTypeapi/${params.url_type}`,
      params,
      false
    );
  },
  // 获得一个申请的数据
  approvalItem(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/FlowTypeapi/getitem`, params);
  },
  // 获得一个申请的数据-保存
  approvalItemSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/FlowTypeapi/save`, params);
  },
  // 获得一个申请的数据-删除
  approvalItemDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/FlowTypeapi/del`, params);
  },
  // 设置审批流程
  approvalProcessSet(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/FlowConfirmapi/get_confirm_manage`,
      params
    );
  },
  // 设置审批流程-is_condition:0单一流程/1多种流程
  changeCondition(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/FlowTypeapi/changecondition`,
      params
    );
  },
  // 审批/设置审批事项-审批人、抄送
  confirmRefer(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Popup/getflow`, params);
  },
  // 设置审批流程-保存
  approvalProcessSave(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/FlowConfirmapi/save`,
      params
    );
  },
  // 设置审批流程-删除
  approvalProcessDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/FlowConfirmapi/del`, params);
  },
  // 自定义事项/设置审批单-点击表格内容显示详情
  approvalForm(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/UdfField/read`, params);
  },
  // 自定义事项/设置审批单-保存
  approvalFormSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/UdfField/save`, params);
  },
  // 自定义事项/设置审批单-删除
  approvalFormDel(params) {
    return myaxios.post(
      BMDURL +
        `/oa/index.php?s=/Flowapi/field_managenew/row_type/${params.row_type}`,
      params
    );
  },
  // 自定义事项/设置审批单-导出
  approvalFormExport(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/UdfField/export/row_type/${params.row_type}`,
      "",
      true,
      "blob"
    );
  },
  // 设置审批流程/添加新流程
  addNewProcess(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/popup/getconfirm&controller=Flow`,
      params
    );
  },
  // 设置审批流程/添加新流程-保存
  addNewProcessSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/FlowConfirm/save`, params);
  },
};

export default SpApi;
