<template>
  <transition name="fade">
    <div class="notification" v-if="show">
      <div class="popup-header">
        <p class="popup-title">通知</p>
      </div>
      <div class="popup-body">
        <p class="popup-message">{{ message }}</p>
      </div>

    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      default: 15000
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    showNotification(msg) {
      console.log(msg)
      console.log(this.message)
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, this.duration);
    }
  }
};
</script>

<style>
/* 添加一些样式 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 200px; /* 宽度200px */
  height: 150px; /* 高度150px */
  background-color: white;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* 层级，确保在顶部 */
}
.notification a{color:#FFFFFF;text-decoration:none;}
.notification a:link {color:#FFFFFF;}
.notification a:visited {color:#FFFFFF;}
.popup-header {
  background-color: #FFD700;/*007bff; /* 深蓝色 */
  color: black;
  padding: 10px 15px;
  font-size: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.popup-body {
  background-color: white;/*#ADD8E6;/*浅蓝色*/
  color: black;
  padding: 5px;
  font-size: 14px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 100px;
}

.popup-message {
  color: black; /* 白色消息文本 */
}
.popup-title {
  color: black; /* 白色消息文本 */
  font-size: 16px;
  text-align: center; /* 文本居中 */
  font-weight: bold; /* 文本加粗 */
}
</style>
