// 问卷调查模块接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const WjdcApi = {
  // 问卷基本信息接口 --- 添加
  // &title=问卷名称&opmode=add
  addQuestInfo({ title, remark }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questinfoapi/save&title=${title}&opmode=add&remark=${remark}`,
      ""
    );
  },
  // 问卷基本信息接口 --- 编辑修改
  editQuestInfo({ id, title, remark }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questinfoapi/save&id=${id}&title=${title}&opmode=edit&remark=${remark}`,
      ""
    );
  },
  // 问卷基本信息接口 --- 查询
  getQuestInfoList() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/questinfoapi/getlist`,
      "",
      false
    );
  },
  // 问卷基本信息接口 --- 删除
  delQuestInfo({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/questinfoapi/del&id=${id}`,
      "",
      false
    );
  },
  // 问卷基本信息接口 --- 查询某一个问卷详情
  getQuestInfoList_id({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/questinfoapi/getlist&eq_id=${id}`,
      ""
    );
  },
  // 问卷题目接口 --- 添加
  // &tid=问卷id&opmode=add&seqno=题号&qtype=题型&qcontent=题干
  addQuestTopic({ tid, seqno, qtype, qcontent }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questtopicapi/save&tid=${tid}&opmode=add&seqno=${seqno}&qtype=${qtype}&qcontent=${qcontent}`,
      ""
    );
  },
  // 问卷题目接口 --- 编辑修改
  // &id=题目id&opmode=edit&seqno=题号&qtype=题型&qcontent=题干
  editQuestTopic({ id, seqno, qtype, qcontent }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questtopicapi/save&id=${id}&opmode=edit&seqno=${seqno}&qtype=${qtype}&qcontent=${qcontent}`,
      ""
    );
  },
  // 问卷题目接口 --- 查询
  // eq_tid=问卷id eq_qtype=题型
  getQuestTopicList({ eq_tid, eq_qtype = "", showLoading }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questtopicapi/getlist&eq_tid=${eq_tid}&eq_qtype=${eq_qtype}&limit=9999`,
      "",
      showLoading
    );
  },
  // 问卷题目接口 --- 删除
  delQuestTopic({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/questtopicapi/del&id=${id}`,
      ""
    );
  },
  // 问卷题选项接口 --- 添加
  // &tid=问卷id&qid=题目id&opmode=add&qno=选项序号&qoption=选项
  addQuestDetail({ tid, qid, qno, qoption }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questdetailapi/save&tid=${tid}&qid=${qid}&opmode=add&qno=${qno}&qoption=${qoption}`,
      "",
      false
    );
  },
  // 问卷题选项接口 --- 编辑修改
  // &id=选项id&opmode=edit&tid=问卷id&qid=题目id&qno=选项序号&qoption=选项
  editQuestDetail({ id, tid, qid, qno, qoption }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questdetailapi/save&id=${id}&opmode=edit&tid=${tid}&qid=${qid}&qno=${qno}&qoption=${qoption}`,
      "",
      false
    );
  },
  // 问卷题选项接口 --- 查询
  // eq_tid=问卷id eq_qid=题目id
  getQuestDetailList({ eq_tid, eq_qid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questdetailapi/getlist&eq_tid=${eq_tid}&eq_qid=${eq_qid}`,
      "",
      false
    );
  },
  // 问卷题选项接口 --- 删除
  delQuestDetail({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/questdetailapi/del&id=${id}`,
      ""
    );
  },

  // 问卷日志接口 --- 添加 (问卷日志记录每次问卷的每道题的答案和得分)
  //  &tid=问卷id&opmode=add&qid=题目id &starttime=开始时间&endtime=结束时间&evtlength=时长（秒）&myanswer =用户答案
  addQuestLog({ tid, qid, starttime, endtime, evtlength, myanswer }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/questlogapi/save&tid=${tid}&qid=${qid}&opmode=add&starttime=${starttime}&endtime=${endtime}&evtlength=${evtlength}&myanswer=${myanswer}`,
      "",
      false
    );
  },
  // 问卷状态接口 --- 添加 (问卷状态记录一个人最终的问卷状态)
  // 为了便于统计，调查问卷要做些简化，queststatus去掉qid参数，只有整个答卷都完成了，才修改status为1
  // &tid=问卷id&opmode=add&qid=题目id 默认status=1通过
  addStatus({ tid, qid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/queststatusapi/save&tid=${tid}&qid=${qid}&opmode=add&status=1`,
      "",
      false
    );
  },
  // 问卷状态接口 --- 编辑修改
  // &id=状态记录id&opmode=edit &qid=题目id
  editStatus({ id, qid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/queststatusapi/save&id=${id}&qid=${qid}&opmode=edit&status=1`,
      "",
      false
    );
  },
  // 问卷状态接口 --- 查询
  // &eq_tid=问卷id&eq_qid=题目id
  getQuestStatus({ eq_tid, eq_qid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/queststatusapi/getlist&eq_tid=${eq_tid}&eq_qid=${eq_qid}`,
      "",
      false
    );
  },
  // 问卷状态接口 --- 查询某人该问卷的状态  eq_tid=问卷id
  getQuestStatus_self({ eq_tid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/queststatusapi/getlist&eq_tid=${eq_tid}&userid=self`,
      "",
      false
    );
  },
  // 问卷状态接口 - 添加 (问卷状态记录一个人最终的问卷状态) &tid=问卷id&opmode=add 默认status=1通过
  // 为了便于统计，调查问卷要做些简化，queststatus去掉qid参数，只有整个答卷都完成了，才修改status为1
  addStatus_self({ tid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/queststatusapi/save&tid=${tid}&opmode=add&status=1`,
      "",
      false
    );
  },
  // 问卷状态接口 - 编辑修改  &id=状态记录id&opmode=edit
  editStatus_self({ id }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/queststatusapi/save&id=${id}&opmode=edit&status=1`,
      "",
      false
    );
  },

  // 问卷统计页接口--问卷题目--- 查询某一个题 eq_id=题目id
  getQuestTopicId({ eq_id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/questtopicapi/getlist&eq_id=${eq_id}`,
      "",
      false
    );
  },
  // 问卷统计页接口--问卷日志--- 查询 加用户id
  getQuestLogList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/questlogapi/getsumlist`,
      params,
      false
    );
  },
  // 问卷统计页接口 --- 查询某个问卷的答题情况
  getQuestList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/queststatusapi/getsumlist`,
      params,
      false
    );
  },
};

export default WjdcApi;
