// 财务接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const CwApi = {
  // 支出明细
  account(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Accountapi/getlist`,
      params,
      false
    );
  },
};

export default CwApi;
