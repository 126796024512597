// 大协议接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const DxyApi = {
  // 大协议查询 大协议列表getlist/大协议管理getlist/接收登记getjslist
  agreementList({ url_type, page = 1, limit = 10, params }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/daxieyiapi/${url_type}&page=${page}&limit=${limit}`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 保存协议 大协议管理daxieyiapi/接收登记daxieyijsapi
  saveAgreement(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/${params.url_type}/save`,
      params
    );
  },
  // 删除协议
  delAgreement({ url_type, id }) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/${url_type}/del`, {
      id,
    });
  },
  // 大协议 -> 导入 大协议管理daxieyiapi/接收登记daxieyijsapi
  importAgreement(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/${params.url_type}/importcontract`,
      params
    );
  },
};

export default DxyApi;
