// 我的
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const WdApi = {
  // 出勤报道
  attend(params) {
    return myaxios.post(
      BMDURL + "/oa/index.php?s=/signapi/dailyreport",
      params
    );
  },
  // 我的打卡记录
  mySignList(params) {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/Signdaydetailapi/mysignlist",
      params
    );
  },
  // 我的工资
  myPayRoll(params) {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/payrollapi/mypayroll",
      params
    );
  },
  // 我的工资条
  mygz(params) {
    return myaxios.get(BMDURL + "/oa/index.php?s=/payrollapi/mygz", params);
  },
  // 我的加班getmyovertime/我的请假getmyvacation/首页待办事项中事前请假getmyprevacation
  myvacation({ url_type, page = 1, limit = 10, params, showLoading = false }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/attendanceapi/${url_type}&page=${page}&limit=${limit}`,
      params,
      showLoading
    );
  },
  // 我的请假类型明细接口
  myvacationType() {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/attendanceapi/getvacationtype",
      "",
      false
    );
  },
  // 每日健康上报
  mylist({ page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/dailyreportapi/getmylist`,
      { page, limit },
      false
    );
  },
  // 今日上报
  dailyReport(params) {
    return myaxios.post(
      BMDURL + "/oa/index.php?s=/Dailyreportapi/save",
      params
    );
  },
  // 修改密码
  resetPwd({ password, repassword, access_token }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/profileapi/reset_pwd`,
      { password, repassword, access_token },
      false
    );
  },
  // 我的工时银行
  workhoursbank({ access_token }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/attendanceapi/workhoursbank`,
      { access_token },
      false
    );
  },
  // 我的证书
  myCertify({ userid, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/certapi/getlist`,
      { userid, page, limit },
      false
    );
  },
  // 添加 / 修改 我的证书
  addCertify(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/certapi/save`, params);
  },
  // 删除 我的证书
  delCertify(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Certapi/del`, params);
  },
  // 修改用户基本信息
  profile(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/profileapi/save`, params);
  },
  // 使用习惯
  userConfigList() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/UserConfigapi/getlist`, "");
  },
  // 保存使用习惯
  userConfig(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/UserConfigapi/save`, params);
  },
  // 待办
  todoList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Todoapi/getlist`,
      params,
      false
    );
  },
  // 待办保存
  todo(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Todoapi/save`, params);
  },
  // 待办删除
  todoDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Todoapi/del`, params);
  },
  // 保密承诺
  secret() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/confidentialagreementapi/getitem`,
      ""
    );
  },
  // 保密承诺---step
  step({ data }) {
    return myaxios.get(
      BMDURL + `/newoa/start/confidentiality/${data}.html`,
      ""
    );
  },
  // 保密承诺提交
  closeSecret(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Confidentialagreementapi/save`,
      params
    );
  },
  // 联系人
  contact() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Contactapi/getlist`, "");
  },
  // 联系人添加
  addContact(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Contactapi/save`, params);
  },
  // 搜索联系人 group为组id,keyword为姓名和电话里的部分内容
  searchContact(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Contactapi/getlist`, params);
  },
  // 删除联系人
  delContact(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Contactapi/del`, params);
  },
  // 通讯录分组列表
  contactGroups() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/UserTagapi/getlist&controller=Contactapi`,
      ""
    );
  },
  // 联系人分组点击详情
  readGroup(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/user_tag/read`, params);
  },
  // 联系人分组新增
  addGroup(params) {
    return myaxios.post(
      BMDURL +
        `/oa/index.php?s=/UserTagapi/${params.opmode}&controller=Contactapi`,
      params
    );
  },
  // 联系人分组删除
  delGroup({ id }) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/UserTagapi/del&id=${id}`, "");
  },
  // 为联系人设置分组
  setGroup(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Contactapi/set_newtag`,
      params
    );
  },
  //  检索方式--->获取 部门 / 岗位 / ... 列表
  typeList({ type }) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Popup/get_${type}_list`, "");
  },
  // 检索方式下的查询接口--->按 部门id / 职位id / ... 查询
  searchId({ type, id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Popup/read&type=${type}&id=${id}`,
      ""
    );
  },
  // 日程列表
  scheduleList({ start_date, end_date }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/Scheduleapi/json&start_date=${start_date}&end_date=${end_date}`,
      ""
    );
  },
  // 日程列表---详情
  scheduleId({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Scheduleapi/json&id=${id}`,
      ""
    );
  },
  // 日程保存
  addSchedule(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Scheduleapi/save`, params);
  },
  // 日程删除
  scheduleDel({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Scheduleapi/del/id/${id}`,
      ""
    );
  },
  // 群组列表
  grouplist() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Groupapi/getlist`, "");
  },
  // 群组编辑保存
  saveGroup(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Groupapi/save`, params);
  },
  // 群组删除
  groupDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Groupapi/del`, params);
  },
  // 历史消息 is_del=0或1 0未读/1已读
  historyList(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/push/getlist`, params, false);
  },
  // 历史消息 未读-标记已读
  signRead(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/push/setread`, params);
  },

  // 建议反馈园地
  adviceList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/adviceapi/getmylist`,
      params,
      false
    );
  },
  // 建议反馈园地 - 新增/编辑
  adviceSave(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/adviceapi/save`, params);
  },
  // 建议反馈园地 - 删除
  adviceDel(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/adviceapi/del`, params);
  },
  // 建议反馈园地-管理员
  adviceAllList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/adviceapi/getlist`,
      params,
      false
    );
  },
};

export default WdApi;
