// 首页
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const indexApi = {
  /**
   * API：菜单列表
   * 接口地址：/oa/index.php?s=/public/getassignmenu
   * 请求方式：GET
   * 请求示例：/oa/index.php?s=/public/getassignmenu
   */
  menu(showLoading=false) {
    // console.log("API：菜单列表", showLoading);
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/public/getassignmenu",
      "",
      showLoading
    );
  },
  // 快捷方式/待办事项/数据概览-今日流量趋势
  shortCut(url_type) {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/public/" + url_type,
      "",
      false
    );
  },
  // 昨日出勤/本周出勤
  sign(url_type) {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/signapi/" + url_type,
      "",
      false
    );
  },
  // 最新公告
  info(params) {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/infoapi/getinfolist",
      { page: params.page, limit: params.limit },
      false
    );
  },
  // 最新消息
  msg(params) {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/messageapi/getallmsg&type=receive",
      { page: params.page, limit: params.limit },
      false
    );
  },
  // 获取实时出勤
  signDayInfo() {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/signapi/getsigndayinfodept",
      "",
      false
    );
  },
  // 今日工作餐
  orderLunch(params) {
    return myaxios.post(
      BMDURL + "/oa/index.php?s=/orderlunchapi/ordersubmit",
      params,
      false
    );
  },
};

export default indexApi;
