// 派遣公司工资发放接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const PqgsgzffApi = {
  // 派遣公司工资发放模块/提交新申请-上传工资明细
  importSalaryDetail(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/zqgzflowapi/${params.url}`,
      params
    );
  },
  // 派遣公司工资发放模块/提交新申请-提交
  addNewApply(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/zqgzflowapi/save`, params);
  },
  // 派遣公司工资发放模块---财务审核...
  finance(page = 1, url) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/zqgzflowapi/folder/fid/${url}`,
      { page }
    );
  },
  // 派遣公司工资发放模块---财务审核--->搜索面板
  salarySearch(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/zqgzflowapi/folder/fid/${params.url}`,
      params
    );
  },
  // 派遣公司工资发放模块 - 财务审核... -> 点击详情
  salaryDetail(params) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/zqgzflowapi/read/id/${params.id}&fid=${params.fid}&opmode=layui&access_token=${params.access_token}`,
      "",
      false
    );
  },
  // 派遣公司工资发放模块 - 财务审核... -> 点击详情 - 详情下方表格数据
  salaryDetailTable({ importtime, fxbank, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/zqgzflowapi/getgz&importtime=${importtime}&fxbank=${fxbank}&page=${page}&limit=${limit}`,
      ""
    );
  },
  // 派遣公司工资发放模块 - 财务审核... -> 点击详情 - 详情下方表格数据 -> 打印
  salaryDetailPrint({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/zqgzflowapi/prtaccount/id/${id}`,
      ""
    );
  },
  // 派遣公司工资发放模块 - 财务审核... -> 下载发薪列表
  downSalaryList(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/zqgzflowapi/downloadflow`,
      params,
      true,
      "blob"
    );
  },
  // 派遣公司工资发放模块 - 财务审核... -> 下载工资明细
  downSalaryDetail(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/zqgzflowapi/downloadgz`,
      params,
      true,
      "blob"
    );
  },
  // 派遣公司工资发放模块 - 撤销
  retreatApply(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/zqgzflowapi/abort`, params);
  },
  // 派遣公司工资发放模块 - 取消
  cancelApply(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/zqgzflowapi/cancel`, params);
  },
  // 派遣公司工资发放模块 - 待审批页面(一键审批/一键否决) 和 待审批详情页(同意/拒绝)
  keyApply(params, url) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/zqgzflowapi/${url}`, params);
  },
};

export default PqgsgzffApi;
