// 学习中心接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const HelpApi = {
  // 学习中心 - 获取左侧上方菜单  参数 master=0 员工 1 管理员 /2 管理岗 3 常见问题
  helpGetGroup(master) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/helpapi/getgroup&master=${master}`,
      "",
      false
    );
  },
  // 学习中心 - 获取左侧菜单  参数 master=0 员工 1 管理员 /2 管理岗 3 常见问题
  helpGetMenu(master) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/helpapi/getmenu&master=${master}`,
      "",
      false
    );
  },
  // 学习中心 - 点击其中一项左侧菜单数的变化
  helpGetMenuEqpid(eq_pid) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/helpapi/getmenu&eq_pid=${eq_pid}`,
      ""
    );
  },
  // 学习中心 - 点击读取详情
  helpRead(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Helpapi/read`, params);
  },
  // 学习中心/管理员手册getadmin 员工手册getemployee 管理岗手册getmanage 常见问题getproblem
  getHelp({ url_type, eq_pid = "" }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Helpapi/${url_type}&eq_pid=${eq_pid}`,
      "",
      false
    );
  },
  // 学习中心/手册 -> 获得指定内容，参数id
  getHelpDetail({ id = "" }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Helpapi/getcontent&id=${id}`,
      ""
    );
  },
  // 学习中心/添砖加瓦->父节点
  helpGetParent() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Helpapi/getparent`,
      "",
      false
    );
  },
  // 添砖加瓦--->保存
  helpSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Helpapi/save`, params);
  },
};

export default HelpApi;
