<template>
  <!-- 首页左侧菜单栏+大框架 -->
  <div>
    <el-container style="height: 100vh">
      <el-aside
        class="aside"
        :class="{ hide: isHide }"
        style="width: 240px; transition: 0.4s"
      >
        <!-- :style="{ width: `${isCollapse ? 64 : 240}px`, transition: '0.4s' }" -->
        <!-- {{ $route.path }} -->

        <el-menu
          :unique-opened="true"
          :router="true"
          :default-active="$route.path"
          :background-color="baseColor"
          text-color="#ffffffd3"
          active-text-color="#fff"
          :style="{ height: '100vh' }"
          class="el-menu-vertical-demo"
        >
          <!-- 左侧菜单折叠 :collapse="isCollapse" -->

          <el-menu-item index="/home/index" class="title_top">
            <img width="30px" src="../assets/logo.png" alt="" />
            <template slot="title">
              <span style="font-size: 1.4em; color: #efefef"
                >&nbsp;&nbsp;{{ top_title }}</span
              >
            </template>
          </el-menu-item>

          <!-- 菜单搜索 -->
          <div class="filterInput">
            <el-input
              placeholder="请输入"
              v-model.trim="filterText"
              clearable
              @clear="clearText(true)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchText"
              ></el-button>
            </el-input>
          </div>
          <template v-if="isShowMenu">
            <menu-tree v-if="list.length" :menuData="list"></menu-tree>
          </template>
        </el-menu>
      </el-aside>

      <el-container style="min-width: 620px">
        <el-header class="header" :class="{ hide: isHide }" height="50px">
          <!-- <i
            v-show="!isCollapse"
            class="el-icon-s-fold shouye"
            @click="isCollapse = true"
          ></i>
          <i
            v-show="isCollapse"
            class="el-icon-s-unfold shouye"
            @click="isCollapse = false"
          ></i> -->
          <!-- 面包屑 -->
          <el-breadcrumb separator=" " style="flex: 1; margin-left: 20px">
            <!-- 首页按钮 -->
            <el-breadcrumb-item :to="{ path: '/home/index' }">
              <i class="el-icon-s-home shouye"></i>
            </el-breadcrumb-item>
            <!-- 返回按钮 -->
            <el-breadcrumb-item>
              <i class="el-icon-back shouye" @click="goBack"></i>
            </el-breadcrumb-item>
            <!-- 刷新按钮 -->
            <el-breadcrumb-item>
              <i
                class="el-icon-refresh-right shouye"
                @click="$router.go(0)"
              ></i>
            </el-breadcrumb-item>

            <!-- <el-breadcrumb-item>
              <span class="shouye toOld" @click="toOldPC">切换到旧版</span>
            </el-breadcrumb-item> -->
          </el-breadcrumb>

          <div class="right_other">
            <el-badge :is-dot="text_count ? true : false">
              <i
                class="el-icon-bell"
                style="cursor: pointer"
                @click="toMsg"
              ></i>
            </el-badge>

            <!-- 换颜色 -->
            <el-color-picker
              style="margin: 0 20px"
              @change="changeBaseColor"
              v-model="baseColor"
              :predefine="predefineColors"
              color-format="hex"
              size="mini"
              popper-class="theme-color-popper"
            ></el-color-picker>

            <!-- 全屏/退出全屏 -->
            <i
              class="icon-full-screen iconfont"
              @click="requestFullScreen"
              v-if="!isFull"
            ></i>
            <i
              class="icon-tuichuquanping iconfont"
              @click="exitFullscreen"
              v-if="isFull"
            ></i>

            <i
              class="el-icon-edit-outline"
              style="margin-left: 20px; cursor: pointer"
              @click="note"
            ></i>
            <noteBook ref="note"></noteBook>

            <div class="right-menu" v-if="isShow">
              <el-dropdown class="avatar-container" trigger="click">
                <div class="avatar-wrapper">
                  <img
                    v-if="
                      user && user.picfile && user.picfile.indexOf('/') != -1
                    "
                    :src="base_url + '/oa/' + user.picfile"
                    class="user-avatar"
                  />
                  <img
                    :src="base_url + '/oa/Uploads/emp_pic/no_avatar.jpg'"
                    class="user-avatar"
                    v-else
                  />
                  <span class="name" v-if="user && user.name">{{
                    user.name
                  }}</span>
                  <i class="el-icon-caret-bottom" />
                </div>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                  <router-link to="/home">
                    <el-dropdown-item> 首 页 </el-dropdown-item>
                  </router-link>
                  <router-link to="/home/profile/baseinfo">
                    <el-dropdown-item> 基本信息 </el-dropdown-item>
                  </router-link>
                  <el-dropdown-item divided @click.native="refreshMenu">
                    <span style="display: block">刷新菜单</span>
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="logout">
                    <span style="display: block">退 出</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-else>
              <span>未登录</span>
            </div>
          </div>
        </el-header>

        <el-header
          class="header_other"
          :class="{ hide: isHide }"
          v-if="breadcrumbs"
        >
          <el-breadcrumb separator="/">
            <template v-for="(item, index) in breadcrumbs">
              <el-breadcrumb-item
                :key="index"
                v-if="item.path"
                :to="{ path: item.path }"
              >
                {{ item.title }}</el-breadcrumb-item
              >
              <el-breadcrumb-item :key="index" v-else>{{
                item.title
              }}</el-breadcrumb-item>
            </template>
          </el-breadcrumb>
        </el-header>

        <el-main class="main_box">
          <!-- 保活 -->
          <keep-alive v-if="isRouterAlive">
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </el-main>
      </el-container>
    </el-container>
    <notification ref="notification" :message="message" />
  </div>

</template>

<script>
import MenuTree from "@/components/index/MenuTree";
import httpApi from "@/http";
import NoteBook from "@/components/home/noteBook.vue";
import baseURL from "@/http/baseURL";
import Notification from "@/components/home/Notification.vue";
import { mapState } from 'vuex';

export default {
  components: { MenuTree, NoteBook,Notification },
  data() {
    return {
      isShowMenu: true, //是否显示左侧菜单
      filterText: "", //菜单搜索
      menuArr: [], //菜单搜索的数据源
      isCollapse: false,
      user: {},
      isShow: true,
      list: [],
      top_title: "",
      breadcrumbs: [],
      baseColor: "#20222a",
      predefineColors: [
        "#20222a",
        "#345",
        "#57a4f6",
        "#009688",
        "#e9957b",
        "#F56C6C",
      ],
      isFull: false, //是否全屏 默认不全屏false 全屏true
      isRouterAlive: true,
      isHide: true, //左侧菜单栏等是否隐藏 默认隐藏
      text_count: 0, //未读数量
      base_url: "",
    };
  },
  provide() {
    return { reload: this.reload };
  },
  computed: {
    ...mapState(['message'])
  },
  watch: {
    $route: {
      async handler(to, from) {
        this.breadcrumbs = this.$route.meta.breadcrumbs;
        // console.log("返回--->路由变化了", to, from);

        // 路由跳转页面滚动顶部
        const container = document.querySelector(".main_box");
        if (container) {
          container.scrollTo(0, 0);
        }
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
    message(newValue) {
      console.log(newValue)
      if (newValue) {
        this.$refs.notification.showNotification(newValue);
      }
    }
  },
  mounted() {
    this.base_url =
      baseURL.BMDURL == "/httpApi"
        ? "http://yunoa.net.cn"
        : "https://yunoa.net.cn";
    // console.log(this.base_url);

    this.text_count = window.sessionStorage.getItem("noReadCount");
    // console.log("未读数量", this.text_count);

    let newPC = window.sessionStorage.getItem("newPC");
    if (newPC) {
      this.isHide = false;
    } else {
      this.isHide = true;
    }
    // console.log("token--->隐藏吗", this.isHide);

    this.getUser();
    // this.getMenu();

    if (
      window.sessionStorage.getItem("menuList") &&
      window.sessionStorage.getItem("menuTitle")
    ) {
      this.list = JSON.parse(window.sessionStorage.getItem("menuList"));
      this.menuArr = JSON.parse(window.sessionStorage.getItem("menuList"));
      this.top_title = window.sessionStorage.getItem("menuTitle");
    } else {
      this.getMenu();
    }

    if (window.localStorage.getItem("themeBaseColor")) {
      this.baseColor = window.localStorage.getItem("themeBaseColor");
    } else {
      // 主题色
      window.localStorage.setItem("themeBaseColor", this.baseColor);
    }

    // // 监听窗口大小改变，this.isFull的值为是否全屏，若是则true，反之false
    // window.onresize = () => {
    //   this.isFull =
    //     document.fullscreenElement ||
    //     document.msFullscreenElement ||
    //     document.webkitFullscreenElement ||
    //     document.mozFullscreenElement;
    // };

    window.addEventListener("keydown", function (event) {
      //禁掉F11的全屏的默认事件,不会禁止F11的退出全屏
      const e = event || window.event;
      if (e && e.keyCode === 122) {
        e.preventDefault();
      }
    });
    document.addEventListener("fullscreenchange", () => {
      this.isFull = !this.isFull;
      //这些监听,可以监听到esc按键按下导致的全屏变化,但是监听不到F11的打开全屏的变化,会自动调用fullScreenEvent方法
    });
    document.addEventListener("mozfullscreenchange", () => {
      this.isFull = !this.isFull;
    });
    document.addEventListener("webkitfullscreenchange", () => {
      this.isFull = !this.isFull;
    });
    document.addEventListener("msfullscreenchange", () => {
      this.isFull = !this.isFull;
    });
  },
  methods: {
    searchText() {
      if (!this.filterText) {
        // 如果没有输入内容点击搜索 则复原数据
        this.clearText(true);
      } else {
        let _this = this;
        function findAndRestoreTree(tree, title) {
          let result = [];
          let obj = {};

          // 扁平化树并记录节点位置
          function flatTree(nodes) {
            nodes.forEach((node) => {
              obj[node.id] = { ...node, pid: node.pid };
              if (node.list) {
                flatTree(node.list);
              }
            });
          }

          // 构建原来的树形结构
          function buildTree(nodes, pid) {
            let list = nodes
              .filter((node) => obj[node].pid == pid)
              .map((id) => obj[id]);
            // console.log(list);

            list.forEach((node) => {
              if (obj[node.id].list) {
                node.list = buildTree(nodes, node.id);
              }
            });
            return list;
          }

          // 扁平化原始树
          flatTree(tree);

          // 根据子节点获取所有父节点的id
          function findParents(treeData, childId, parents = []) {
            for (let i = 0; i < treeData.length; i++) {
              const node = treeData[i];
              if (node.id == childId) {
                // 找到了目标子节点，返回其所有父节点id
                return [...parents, node.id];
              } else if (node.list && node.list.length) {
                // 递归遍历子节点的所有子节点
                const result = findParents(node.list, childId, [
                  ...parents,
                  node.id,
                ]);
                if (result) {
                  // 找到了目标子节点，返回其所有父节点id
                  return result;
                }
              }
            }
            // 没有找到目标子节点
            return null;
          }

          // 将所有父节点存下来
          let rootNode = [];
          // 在扁平化后的树中查找包含特定值的节点
          for (let id in obj) {
            if (obj[id].title.includes(title)) {
              rootNode.push(...findParents(_this.menuArr, obj[id].id));
            }
          }

          // 父节点数组去重
          result = Array.from(new Set(rootNode));
          // console.log(rootNode, result);

          // 构建原来的树形结构
          return buildTree(result, "0");
        }

        // 需要从菜单数据源中寻找
        let resultArr = findAndRestoreTree(this.menuArr, this.filterText);

        // 按数组中的sort排序
        this.list = resultArr.sort((a, b) => a.sort - b.sort);

        // console.log("查询菜单", this.filterText, this.list);

        if (resultArr.length == 0) {
          // 如果查询的结果为零 需要将菜单数据复原 但是视图不显示
          this.isShowMenu = false;
          this.$message({
            showClose: true,
            message: "暂无数据",
            type: "warning",
          });

          this.clearText();
        } else {
          this.isShowMenu = true;
        }
      }

      // console.log("菜单", this.isShowMenu, this.list);
    },
    clearText(e) {
      // console.log("清除", e);
      // 如果是点击清除按钮 显示菜单
      if (e) this.isShowMenu = true;
      if (
        window.sessionStorage.getItem("menuList") &&
        window.sessionStorage.getItem("menuTitle")
      ) {
        this.list = JSON.parse(window.sessionStorage.getItem("menuList"));
        this.menuArr = JSON.parse(window.sessionStorage.getItem("menuList"));
        this.top_title = window.sessionStorage.getItem("menuTitle");
      } else {
        this.getMenu();
      }
    },
    // 刷新菜单
    refreshMenu() {
      this.getMenu();
    },
    // 未读消息
    toMsg() {
      this.text_count = 0;
      //清除某一项
      window.sessionStorage.removeItem("noReadCount");

      this.$router.push("/home/message/list");
    },
    // 便签
    note() {
      this.$nextTick(() => {
        this.$refs.note.getList();
      });
    },
    // 切换到旧版PC
    toOldPC() {
      let url = location.protocol + "//yunoa.net.cn/newoa/start/#/";
      // console.log(location, url);
      // 旧版地址 https://yunoa.net.cn/newoa/start/#/

      window.sessionStorage.clear();
      window.open(url, "_self");
    },
    // 解决使用keepalive保活二次进入页面显示首次缓存问题
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
    // 全屏
    requestFullScreen() {
      const box = document.documentElement;
      if (box.RequestFullScreen) {
        box.RequestFullScreen();
      } else if (box.requestFullscreen) {
        box.requestFullscreen();
      } else if (box.mozRequestFullScreen) {
        box.mozRequestFullScreen();
      } else if (box.msRequestFullscreen) {
        box.msRequestFullscreen();
      } else if (box.oRequestFullscreen) {
        box.oRequestFullscreen();
      } else if (box.webkitRequestFullscreen) {
        box.webkitRequestFullScreen();
      }

      // this.isFull = !this.isFull;
    },
    // 退出全屏
    exitFullscreen() {
      const el = document;
      if (el.exitFullscreen) {
        el.exitFullscreen();
      } else if (el.mozCancelFullScreen) {
        /* Firefox */
        el.mozCancelFullScreen();
      } else if (el.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        el.webkitExitFullscreen();
      } else if (el.msExitFullscreen) {
        /* IE/Edge */
        el.msExitFullscreen();
      }

      // this.isFull = !this.isFull;
    },
    goBack() {
      if (this.$route.name == "gwjsks/ks") {
        this.$message.warning({
          message: "正在考试，不可返回",
          showClose: true,
        });
      }

      if (this.$route.name == "wjdcmk/twj") {
        this.$message.warning({
          message: "正在填问卷，不可返回",
          showClose: true,
        });
      }

      // console.log(this.$route, location);

      if (location.pathname.indexOf("h5pc") != -1) this.$router.go(-1);
    },
    changeBaseColor(e) {
      window.localStorage.setItem("themeBaseColor", this.baseColor);
      // console.log("改变主题色", e == this.baseColor);
    },
    async getMenu() {
      let res = await httpApi.IndexApi.menu();
      if (res.data.code === 0) {
        this.list = res.data.data;
        this.menuArr = res.data.data;
        this.top_title = res.data.title ? res.data.title : "汇办公";

        window.sessionStorage.setItem(
          "menuList",
          JSON.stringify(res.data.data)
        );
        window.sessionStorage.setItem(
          "menuTitle",
          res.data.title ? res.data.title : "汇办公"
        );
      }

      // // 假的!!!!!!!!!
      // this.list
      //   .filter((e) => {
      //     return e.title == "问卷调查模块";
      //   })[0]
      //   .list.push({
      //     title: "问卷调查统计",
      //     id: "wjdcmk/wjdctj",
      //     url2: "wjdcmk/wjdctj",
      //   });

      // console.log("日常办公--->", res, this.list);
    },
    getUser() {
      this.user = JSON.parse(window.sessionStorage.getItem("user"));
      // console.log("user", this.user);
      if (this.user === undefined) {
        this.isShow = false;
      }
    },
    async logout() {
      // 退出登录
      let res = await httpApi.AdminApi.loginOut({
        access_token: window.sessionStorage.getItem("token"),
      });
      // console.log("退出登录", res);

      window.sessionStorage.clear();
      this.$router.push("/login");

      // 退出登录后禁止返回
      history.pushState(null, null, document.URL);
      window.addEventListener(
        "popstate",
        function (e) {
          history.pushState(null, null, document.URL);
        },
        false
      );
    },
  },
};
</script>

<style lang="scss">
// 主题颜色隐藏清空按钮
.theme-color-popper {
  .el-button--text {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
// 隐藏样式
.hide {
  display: none !important;
}

// 搜索菜单
.filterInput {
  margin: 0 auto;
  width: 90%;
  ::v-deep .el-input__inner {
    border: none;
    // background-color: #333 !important;
    // color: rgba(255, 255, 255, 0.827);
  }
  ::v-deep .el-input-group__append {
    border: none;
    background-color: #fff !important;
    // .el-icon-search {
    //   color: rgba(255, 255, 255, 0.827);
    // }
  }
}

.right_other {
  display: flex;
  align-items: center;

  .iconfont {
    cursor: pointer;
    font-size: 1em;
  }
}
.el-main {
  padding: 4px;
}
// 头部首页小房子
.shouye {
  color: #333;
  cursor: pointer;
}
// 回到旧版
.toOld {
  font-size: 1.2em;
}
// // 标题禁用
// .title_top {
//   opacity: 1 !important;
// }
// 标题
.title_top {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // position: fixed; //左侧菜单最上方固定
  // left: 0;
  // top: 0;
  // z-index: 1;
  // width: 240px;
  // height: 50px;
  // line-height: 50px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;

  i {
    font-size: 1.3em;
  }
}
.header_other {
  height: 40px !important;
  display: flex;
  align-items: center;

  .el-breadcrumb {
    margin: 0 !important;
  }

  // 面包屑激活
  .active_bread {
    cursor: pointer !important;
  }
}

.aside::-webkit-scrollbar {
  display: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
  // margin-top: 50px; //距离左侧菜单最上方
}

.right-menu {
  float: right;
  height: 100%;
  line-height: 50px;
  margin-left: 20px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .name {
    color: black;
    vertical-align: middle;
    margin-left: 5px;
  }

  .right-menu-item {
    display: inline-block;
    padding: 0 8px;
    height: 100%;
    font-size: 18px;
    color: black;
    vertical-align: text-bottom;

    &.hover-effect {
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }
  }

  .avatar-container {
    margin-right: 30px;

    .avatar-wrapper {
      // margin-top: 5px;
      position: relative;

      .user-avatar {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        vertical-align: middle;
      }

      .user-dropdown {
        color: blue;
      }

      .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        right: -15px;
        top: 23px;
        font-size: 12px;
        color: black;
      }
    }
  }
}
</style>
