//在该文件中，应导出axios发请求的所有业务方法
import AdminApi from "./apis/AdminApi";
import IndexApi from "./apis/IndexApi";
import WdApi from "./apis/WdApi";
import SqApi from "./apis/SqApi";
import PxApi from "./apis/PxApi";
import KsApi from "./apis/KsApi";
import WjdcApi from "./apis/WjdcApi";
import PqgsgzffApi from "./apis/PqgsgzffApi";
import GgApi from "./apis/GgApi";
import GxwjApi from "./apis/GxwjApi";
import GzApi from "./apis/GzApi";
import DahtApi from "./apis/DahtApi";
import HelpApi from "./apis/HelpApi";
import GsszApi from "./apis/GsszApi";
import DxyApi from "./apis/DxyApi";
import KqglApi from "./apis/KqglApi";
import CwApi from "./apis/CwApi";
import XgjApi from "./apis/XgjApi";
import JtgsApi from "./apis/JtgsApi";
import RwApi from "./apis/RwApi";
import ZgyApi from "./apis/ZgyApi";
import SpApi from "./apis/SpApi";

const httpApi = {
  AdminApi, //登录
  IndexApi, //首页
  WdApi, //我的
  SqApi, //申请、审批
  PxApi, //培训
  KsApi, //培训考试
  WjdcApi, //问卷调查
  PqgsgzffApi, //派遣公司工资发放
  GgApi, //公告
  GxwjApi, //共享文件
  GzApi, //工资
  DahtApi, //档案合同
  HelpApi, //学习中心
  GsszApi, //公司设置
  DxyApi, //大协议
  KqglApi, //考勤管理
  CwApi, //财务
  XgjApi, //小工具
  JtgsApi, //集团公司
  RwApi, //任务
  ZgyApi, //专管员
  SpApi, //审批
};

export default httpApi;
