// 管理员登录接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const adminApi = {
  /**
   * 执行登录业务
   * @param {Object} params {username:xx,  password:xxx}
   */
  login(params) {
    // return myaxios.post(BMDURL + "/user/login", params);
    return myaxios.get(BMDURL + "/oa/index.php?s=/public/layuilogin", params);
  },
  // 登录后获取用户信息接口
  userInfo(params) {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/profileapi/baseinfo",
      params,
      false
    );
  },
  // 退出登录接口
  loginOut(params) {
    return myaxios.get(BMDURL + "/oa/index.php?s=/public/layuilogout", params);
  },

  // 保持和后台不断联系 避免返回1001 -> 心脏跳动
  HeartBeat() {
    return myaxios.get(BMDURL + "/oa/index.php?s=/push/server", "", false);
  },
};

export default adminApi;
