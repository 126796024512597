// 工资接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const GzApi = {
  // 工资固定项的查询接口
  getpayrollfix(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/userapi/getpayrollfix`,
      params,
      params.showLoading ? params.showLoading : false
    );
  },
  // 单个添加工资固定项表单 -> 部门deptapi/getlist 职位positionapi/getlist
  fixForm(url) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/${url}/getlist`, "", false);
  },
  // 工资固定项 -> 添加 / 修改
  saveFix(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/userapi/save`, params);
  },
  // 工资固定项 -> 删除
  userDel({ user_id, access_token }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/Userapi/del&user_id=${user_id}&access_token=${access_token}`,
      ""
    );
  },
  // 工资固定项 -> 导入
  importFix(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/userapi/importfix`, params);
  },

  // 工资月上报项的查询接口
  getpayrollmonth(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payrollmonthapi/getlist`,
      params,
      params.showLoading ? params.showLoading : false
    );
  },
  // 工资固定项 -> 添加 / 修改
  saveMonth(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Payrollmonthapi/save`,
      params
    );
  },
  // 工资月上报项 -> 导入
  importMonth(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payrollmonthapi/importone`,
      params
    );
  },
  // 工资月上报项 -> 删除
  monthDel({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payrollmonthapi/del&id=${id}`,
      ""
    );
  },

  // 工资保险部分、基础薪资
  getpayrollinsure(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollinfoapi/getlist`,
      params,
      params.opmode_type == "export" ? true : false //导出所有行true
    );
  },
  // 工资保险部分-新增add/编辑edit
  savepayrollinsure(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/payrollinfoapi/save`, params);
  },
  // 工资保险部分-保险规则设置
  getinsurancerule(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/insuranceruleapi/getlist`,
      params,
      false
    );
  },
  // 保险规则设置-新增add/编辑edit
  saveinsurancerule(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/insuranceruleapi/save`,
      params
    );
  },
  // 保险规则设置-删除
  delinsurancerule(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/insuranceruleapi/del`,
      params
    );
  },
  // 应用保险规则
  useinsurancerule(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollinfoapi/updateinsurance`,
      params
    );
  },
  // 导入基础数据
  insertinfo(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollinfoapi/insertinfo`,
      params
    );
  },
  // 工资保险部分 -> 导入
  importInsertinfo(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollinfoapi/importone`,
      params
    );
  },
  // 固定薪资项-设置薪资规则
  setSalary(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Baseincomingapi/getlist`,
      params,
      false
    );
  },
  // 固定薪资项-应用薪资规则-刷新数据
  salaryRefresh(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollinfoapi/refreshinfo`,
      params
    );
  },
  // 固定薪资项-设置薪资规则-新增add/编辑edit
  saveSalaryRule(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Baseincomingapi/save`,
      params
    );
  },
  // 固定薪资项-设置薪资规则-删除
  delSalaryRule(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Baseincomingapi/del`, params);
  },
  // 固定薪资项 -> 导入
  importSalary(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollinfoapi/importjx`,
      params
    );
  },
  // 固定薪资项 -> 导入基础薪资
  importBaseInfo(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollinfoapi/importpayrollinfo`,
      params
    );
  },
  // 查询工资表
  getPayrollList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollinfoapi/getpayrolllist`,
      params,
      params.opmode_type == "export" ? true : false //导出所有行true
    );
  },

  // 工资条导入和关联
  gztList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payrollapi/getgzlist`,
      params,
      false
    );
  },
  // 工资条导入和关联-点击行显示详情
  gztReadDetail(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payrollapi/getgzdetail`,
      params,
      false
    );
  },
  // 工资条导入和关联-点击关联
  gztSet(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Payrollapi/setgz`, params);
  },
  // 工资条导入和关联-导入
  importGzt(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/payrollapi/importgz`, params);
  },
  // 工资条导入和关联-删除
  delGzt(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/payrollapi/delgz`, params);
  },
  // 工资条列表
  gztInfo(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payrollapi/getgzinfo`,
      params,
      false
    );
  },
  // 工资条列表-点击行显示详情
  gztRead(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payrollapi/gzread`,
      params,
      false
    );
  },
  // 工资条列表-导出
  gztExport(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/payrollapi/gzread`,
      params,
      true,
      "blob"
    );
  },
  // 工资条列表-发放工资条
  gztSend(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payrollapi/gzsetfinal`,
      params
    );
  },
  // 六项扣除列表
  sixList() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollapi/getpaysixlist`,
      "",
      false
    );
  },
  // 六项扣除列表-点击行显示详情
  sixRead(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollapi/getpaysixdetail`,
      params,
      false
    );
  },

  // 应发工资导入和关联
  yfgzList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollapi/getimportpayrolllist`,
      params,
      false
    );
  },
  // 应发工资导入和关联-点击行显示详情
  yfgzReadDetail(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollapi/getpayrolldetail`,
      params,
      false
    );
  },
  // 应发工资导入和关联-点击关联
  yfgzSet(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollapi/setpayroll`,
      params
    );
  },
  // 应发工资导入和关联-导入
  importYfGz(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/payrollapi/importpayroll`,
      params
    );
  },
  // 应发工资导入和关联-删除
  delYfGz(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/delpayroll`,
      params
    );
  },
};

export default GzApi;
