// 任务接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const RwApi = {
  // 发布新任务-发布 / 处理任务-修改
  saveTask(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Taskapi/save`, params);
  },
  // 处理任务
  dealTask(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Taskapi/getfolder&fid=${params.url_type}`,
      params,
      false
    );
  },
  // 处理任务-删除
  delTask(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Taskapi/del/id/${params.id}`);
  },
  // 处理任务-获取详情
  detailTask(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Taskapi/getitem&id=${params.id}`
    );
  },
  // 处理任务-任务处理 status:10执行情况 21转交任务 22拒绝接受
  handleTask(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Taskapi/save_log`, params);
  },
  // 处理任务-我来处理
  letMeDoTask(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Taskapi/let_me_do`, params);
  },
};

export default RwApi;
