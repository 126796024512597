// 小工具接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const XgjApi = {
  // 内部通讯录-部门列表
  staffList() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Staffapi/getlist`);
  },
  // 内部通讯录-部门列表/部门id
  staffId(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Staffapi/read/id/${params.id}`,
      "",
      false
    );
  },
  // 日报
  getWorkLog(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/worklogapi/getmylist`,
      params,
      false
    );
  },
  // 日报-发布日报
  saveWorkLog(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Worklogapi/save`, params);
  },
  // 消息/发送新消息-发送
  saveMessage(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Messageapi/save`, params);
  },
  // 消息/阅读/回复
  messageList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/messageapi/getallmsg&type=${params.url_type}`,
      params,
      false
    );
  },
  // 报表/到岗人数分时段报表
  getSum(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/signapi/signhoursum`,
      params,
      false
    );
  },

  // 通讯录-客户信息Customerapi/供应商信息Supplierapi
  contactList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/${params.url_type}/getlist`,
      params
    );
  },
  // 信息-导出
  exportContact(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/${params.url_type}/export`,
      "",
      true,
      "blob"
    );
  },
  // 通讯录-信息添加
  addContact(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/${params.url_type}/save`,
      params
    );
  },
  // 删除信息
  delContact(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/${params.url_type}/del`,
      params
    );
  },
  // 通讯录分组列表
  contactGroups(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/${params.url_type}/tag_managenew`,
      params
    );
  },
  // 通讯录分组点击详情
  readGroup(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/system_tag/read`, params);
  },
  // 通讯录分组保存
  saveGroup(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/SystemTag/save`, params);
  },
  // 通讯录分组删除
  delGroup(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/${params.url_type}/tag_manage`,
      params
    );
  },
  // // 通讯录分组删除
  // delGroup({ id }) {
  //   return myaxios.get(
  //     BMDURL + `/oa/index.php?s=/SystemTagapi/del&id=${id}`,
  //     ""
  //   );
  // },
  // 为通讯录设置分组
  setGroup(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/${params.url_type}/set_tag`,
      params
    );
  },

  // 个税计算/已导入六项抵扣列表
  getPaysixList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Paytoolapi/getpaysixlist`,
      params,
      false
    );
  },
  // 个税计算/已导入六项抵扣列表-删除
  delPaysix(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Paytoolapi/delsix`, params);
  },
  // 个税计算/已导入六项抵扣列表-点击行获取右侧表格详情
  getPaysixRead(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Paytoolapi/paysixread`,
      params,
      false
    );
  },
  // 个税计算/已导入应发工资列表
  getPayrollList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Paytoolapi/getpayrolllist`,
      params,
      false
    );
  },
  // 个税计算/已导入应发工资列表-点击行获取右侧表格详情
  getPayrollRead(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Paytoolapi/read`,
      params,
      false
    );
  },
  // 个税计算/已导入应发工资列表-应发关联
  payrollSet(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Paytoolapi/setpayroll`,
      params
    );
  },
  // 个税计算/已导入应发工资列表-删除
  delPayroll(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Paytoolapi/del`, params);
  },
  // 个税计算/应发工资列表
  getPaytoolList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Paytoolapi/getpaytoollist`,
      params,
      false
    );
  },
  // 个税计算/应发工资列表-计算个税
  calcTax(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Paytoolapi/calculate`,
      params
    );
  },
  // 个税计算/应发工资列表-导入并覆盖
  reimport(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Paytoolapi/reimport`, params);
  },
  // 个税计算/应发工资列表-锁定setlock/解锁setunlock
  lockFn(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Paytoolapi/${params.url_type}`,
      params
    );
  },
};

export default XgjApi;
