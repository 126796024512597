import axios from "axios";
import qs from "qs";
import { Message, Loading } from "element-ui";
import router from "@/router";

const instance = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120000, // request timeout
  responseType: "json",
  withCredentials: true, // 是否允许带cookie这些
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  },
});

let setLoding = false;
instance.interceptors.request.use(
  (config) => {
    //setLoding = config.loadingConfig;
    config.responseType = config.type;
    // console.log("请求拦截", config);
//不显示加载过程
//    if (config.loadingConfig) {
//      Loading.service({
//        fullscreen: true,
//        text: "加载中...",
//        background: "rgba(0, 0, 0, 0.7)",
//        spinner: "el-icon-loading",
//        lock: true,
//     });
//    }
    return config;
  },
  (error) => {
    // console.log("请求错误", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (config) => {
    // console.log("响应拦截", config);

    if (
      config.data &&
      config.data.code === 1001 &&
      config.config.url !== "/api/oa/index.php?s=/public/layuilogin"
    ) {
      Message({
        message: config.data.msg,
        type: "warning",
        showClose: true,
        duration: 1000,
        onClose: () => {
          // window.location.href = "/";
          router.push({ path: "/" });
        },
      });
    } else if (config.request.responseType == "blob") {
      // 导出操作的处理
      if (config.status == 200) {
        // 生成随机数-文件名
        function generateRandomFilename() {
          const timestamp = new Date().getTime(); // 当前时间戳
          const randomPart = Math.floor(Math.random() * 101) + 100; // 生成100~200之间的随机整数
          return `${timestamp}${randomPart}.xlsx`;
        }

        const link = document.createElement("a"); //创建a标签
        // let blob = new Blob([config.data],{type: 'application/vnd.ms-excel'}); //如果后台返回的不是blob对象类型,先定义成blob对象格式,该type导出为xls格式
        let blob = config.data; //如果后台返回的直接是blob对象类型,直接获取数据

        let fileName = config.headers["content-disposition"]
          ? config.headers["content-disposition"].split("=")[1]
          : generateRandomFilename(); //拆解获取文件名,如果后端有给返回文件名的话
        // console.log(generateRandomFilename(), "*******", fileName);

        link.style.display = "none"; //隐藏

        // 兼容不同浏览器的URL对象
        const url = window.URL || window.webkitURL || window.moxURL;
        link.href = url.createObjectURL(blob);

        link.download =
          decodeURIComponent(fileName).indexOf("csv") != -1
            ? decodeURIComponent(fileName).split(".")[0].split('"')[1] + ".csv"
            : decodeURIComponent(fileName); //下载的文件名称

        link.click(); //触发click
        window.URL.revokeObjectURL(url); //URL.revokeObjectURL()方法会释放一个通过URL.createObjectURL()创建的对象URL. 当你要已经用过了这个对象URL,然后要让浏览器知道这个URL已经不再需要指向对应的文件的时候,就需要调用这个方法.
      } else {
        Message({
          showClose: true,
          message: "下载文件出错，请稍后再试",
          type: "warning",
        });
      }
    }

    if (setLoding) {
      Loading.service().close();
    }
    setLoding = false;
    return config;
  },
  (error) => {
    // console.log("响应错误", error);

    Message({
      message: "系统繁忙，请稍后再试",
      type: "warning",
      showClose: true,
    });
    if (setLoding) {
      Loading.service().close();
    }
    setLoding = false;

    return Promise.reject(error);
  }
);

const myaxios = {
  /**
   * 用于发送get请求
   * @param {*} url 请求资源路径
   * @param {*} params 请求参数（object形式）
   * @returns   Promise
   */
  get(url, params, loadingConfig = true, type = "json") {
    return instance({
      url,
      method: "get",
      params,
      loadingConfig,
      type,
    });
  },

  /**
   * 用于发送post请求
   * @param {*} url 请求资源路径
   * @param {*} params 请求参数（object形式）
   * @returns   Promise
   */
  post(url, params, loadingConfig = true, type = "json") {
    return instance({
      url,
      method: "post",
      data: qs.stringify(params),
      loadingConfig,
      type,
    });
  },
};

export default myaxios;
