// 公告接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const GgApi = {
  // 全部公告--->所有公告...
  allNotice({ url, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/infoapi/${url}&page=${page}&limit=${limit}`,
      "",
      false
    );
  },
  // 全部公告--->所有公告...点击标题跳转-公告详情
  ggDetail({ id, access_token }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/infoapi/read/id/${id}&json=1&access_token=${access_token}`,
      ""
    );
  },
  // 全部公告--->所有公告...--->点击标题跳转详情--->详情下的签名接口
  signature(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/infoapi/sign/id/${params.id}`,
      params
    );
  },
  // 公司公告
  companyNotice({ fid, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/infoapi/folder/fid/${fid}&opmode=layui&page=${page}&limit=${limit}`,
      "",
      false
    );
  },
  // 公司公告 -> 发布新公告
  publishNotice(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Infoapi/save`, params);
  },
  // 我发布的通知--->签收情况详情(签收人员列表)
  signNotice({ url, id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/infoapi/${url}&id=${id}`,
      "",
      false
    );
  },
  // 我发布的通知--->删除
  delNotice({ id }) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/infoapi/del/id/${id}`, "");
  },
  // 首页--->最新消息 点击内容跳转-消息详情
  msgDetail({ id, access_token }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/messageapi/read&id=${id}&access_token=${access_token}`,
      ""
    );
  },
  // 公告/管理公告栏
  manageBulletinBoard() {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/SystemFolderapi/getfolderlist&controllername=info`,
      "",
      false
    );
  },
  // 公告/管理公告栏 - 点击行显示详情
  readBulletin(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/SystemFolderapi/read`,
      params
    );
  },
  // 公告/管理公告栏 - 新增/保存
  saveBulletin(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/SystemFolderapi/save&controllername=Info`,
      params
    );
  },
  // 公告/管理公告栏 - 删除
  delBulletin(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/SystemFolderapi/del`,
      params
    );
  },
};

export default GgApi;
