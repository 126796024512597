// 档案合同接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const DahtApi = {
  // 人员信息的查询接口
  getUserList(params) {
    // console.log(params);
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/userapi/getlist`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 人员信息/搜索面板 -> 审批组
  workGroup(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/userapi/getworkgroup`,
      params,
      false
    );
  },
  // 合同信息
  contractList({ userid, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/contractapi/getlist&userid=${userid}&page=${page}&limit=${limit}`,
      "",
      false
    );
  },
  // 人员信息 -> 删除
  userDel({ user_id, access_token }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/Userapi/del&user_id=${user_id}&access_token=${access_token}`,
      ""
    );
  },
  // 人员信息 -> 添加 / 修改
  saveUser(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Userapi/save`, params);
  },
  // 人员信息 -> 部门deptapi/getlist 职位positionapi/getlist
  getForm(url) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/${url}/getlist`, "", false);
  },
  // 人员信息 -> 设置密码
  setPwd(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Userapi/reset_pwd`, params);
  },
  // 人员信息 -> 审核新人
  userListAdmit(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/userapi/getadmitlist`,
      params,
      false
    );
  },
  // 人员信息--->审核新人--->通过审核
  userListAdmitUser({ user_id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/userapi/admit&user_id=${user_id}&opmode=admit`,
      "",
      false
    );
  },
  // 人员信息 -> 批量导入
  importOne(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/userapi/importone`, params);
  },

  // 人员信息 -> 批量离职
  importLeave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/userapi/batchleave`, params);
  },

  // 组织结构 -> 部门树
  orgStructTree() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/deptapi/getdplist`, "");
  },
  // 组织结构 -> 人员信息
  orgStruct({ id, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/userapi/getdeptuser&id=${id}&page=${page}&limit=${limit}`,
      "",
      false
    );
  },
  // 组织结构 -> 导出
  exportOrgStruct({ deptid }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/userapi/export&deptid=${deptid}`,
      "",
      true,
      "blob"
    );
  },
  // 人员结构
  personStruct({ url_type }) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/systemapi/${url_type}`, "");
  },
  // 入职信息查询接口：Entryinfoapi/getlist
  entryList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Entryinfoapi/getlist`,
      params,
      false
    );
  },
  // 入职信息某个详情
  entryInfo(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Entryinfoapi/readinfo`,
      params
    );
  },
  // 入职信息修改
  entryInfoSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Entryinfoapi/save`, params);
  },
  // 入职信息 -> 批量导入
  importEntry(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Entryinfoapi/importone`,
      params
    );
  },
  // 入职信息 -> 导出
  exportEntry() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Entryinfoapi/export`,
      "",
      true,
      "blob"
    );
  },
  // 离职信息查询
  dimissionList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Dimissionapi/getlist`,
      params,
      false
    );
  },
  // 离职信息某个详情
  dimissionInfo(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Dimissionapi/readinfo`,
      params
    );
  },
  // 离职信息修改
  dimissionInfoSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Dimissionapi/save`, params);
  },
  // 离职信息 -> 导出
  exportDimission() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Dimissionapi/export`,
      "",
      true,
      "blob"
    );
  },
  // 离职办理 -> 账号回车接口
  leaveEmpno({ empno }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/leaveapi/empnosubmit&empno=${empno}`
    );
  },
  // 离职办理 -> 立即提交接口
  leaveSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/leaveapi/save`, params);
  },
  // 离职列表 -> 查询
  leaveList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/leaveapi/getlist`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 离职列表 -> 删除
  leaveListDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/leaveapi/del`, params);
  },
  // 退休人员
  retiredList({ day1, day2, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/contractapi/getretiredlist&day1=${day1}&day2=${day2}&page=${page}&limit=${limit}`,
      "",
      false
    );
  },
  // 合同管理 -> 查询
  contractManageList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/contractapi/getuserlist`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 合同管理 -> 导入合同
  importContract(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/contractapi/importcontract`,
      params
    );
  },
  // 合同管理 -> 导出人员getexpirylist/导出合同getcontractlist
  contractExportType(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/contractapi/${params.url_type}`,
      params,
      true,
      "blob"
    );
  },
  // 合同管理 - 导出项设置
  exportSet() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/fldnameapi/getlist&tabname=usercontract`,
      "",
      false
    );
  },
  // 合同管理 - 提交导出项设置
  saveExportset(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Fldnameapi/saveset`, params);
  },
  // 合同管理 - 点击'续签' -> 续签合同
  addContract(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/contractapi/save`, params);
  },
  // 合同管理 - 点击合同详情 -> 编辑合同
  editContract(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/companyadminapi/newcontract`,
      params
    );
  },
  // 合同管理--->点击'终止'/'解除' ---> 终止/解除 合同
  endContract(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/contractapi/contractdetail`,
      params
    );
  },
  // 合同管理--->点击'删'--->删除合同
  delContract(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Contractapi/del`, params);
  },
  // 证书管理 -> 查询
  certManageList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/certapi/getuserlist`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 证书管理 -> 导出证书
  certExportType(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/certapi/getlist`,
      params,
      true,
      "blob"
    );
  },
  // 证书管理 -> 点击姓名 -> 证书信息
  certList({ userid, page = 1, limit = 10 }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/certapi/getlist&userid=${userid}&page=${page}&limit=${limit}`,
      "",
      false
    );
  },
  // 证书管理 -> 添加/编辑 证书
  certSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/certapi/save`, params);
  },
  // 证书管理--->点击'删'--->删除证书
  delCert(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Certapi/del`, params);
  },
  // 证书管理 -> 导入证书
  importCert(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/certapi/importcert`, params);
  },
  // 身份证查询 (查某个人或身份证号在系统中有多少账号) 查身份证&personid=3521 查姓名&name=吴
  getPersonidList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/userapi/getpersonidlist`,
      params,
      false
    );
  },
};

export default DahtApi;
