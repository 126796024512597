// 申请、审批接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const SqApi = {
  // 申请列表
  flowList() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/flowapi/getflowlist2`, "");
  },
  // 新换班
  changeShifts() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/Shiftapi/addwx`, "");
  },
  // 查询某人某日班务
  searchShifts(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Signapi/getcurtimeplan`,
      params
    );
  },
  // 换班提交
  submitShifts(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/shiftapi/save`, params);
  },
  // 申请信息接口
  applyMsg(applyId) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/flowapi/add/type/${applyId}`,
      ""
    );
  },
  // 申请提交this.form.step = 20/存草稿箱this.form.step = 10
  addFlow(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/flowapi/save`, params);
  },
  // 搜索面板--->部门
  deptList(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/deptapi/getdplista`,
      params,
      false
    );
  },
  // 搜索面板--->申请事项
  searchPanelType(access_token) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/FlowTypeapi/getlist&access_token=${access_token}`,
      "",
      false
    );
  },
  // 搜索面板--->搜索
  searchPanel(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/flowapi/folder/fid/${params.url}`,
      params,
      true,
      params.opmode == "layui" ? "json" : "blob"
    );
  },
  // 草稿箱/我提交的申请
  sqData(page = 1, url) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/flowapi/folder/fid/${url}`, {
      opmode: "layui",
      page,
    });
  },
  // 申请撤销
  retreatApply(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/flowapi/abort`, params);
  },
  // 申请取消
  cancelApply(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/flowapi/cancel`, params);
  },
  // 申请信息明细接口
  applyDetail(params) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/flowapi/read/id/${params.applyId}&fid=${params.fId}&opmode=layui`,
      ""
    );
  },
  // 一键通过/一键拒绝
  keyApply(params, url) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/flowapi/${url}`, params);
  },
  // 待审批-同意/拒绝
  waitConfirm(params, url) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/flowapi/${url}`, params);
  },
  // 换班确认 - 待确认列表: 1、shiftapi/getadmitlist
  shiftList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/shiftapi/getadmitlist`,
      params
    );
  },
  // 换班确认 - 通过: 2、shiftapi/admit / 拒绝: 3、shiftapi/reject 参数Post opmode: admit  sid: 4590  ajax: 1
  shiftHandle(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/shiftapi/${params.url}`,
      params
    );
  },
  // 申请、审批模块/支出凭证
  payCertify(id, showLoading=false) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/flowapi/getaccount/id/${id}`,
      "",
      showLoading
    );
  },
  // 申请、审批模块/支出凭证-点击打印请求接口
  printPayCertify(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Flowapi/saveprt`,
      params,
      false
    );
  },

  // 审批/设置高级权限
  roleDuty(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/roleapi/roleduty `, params);
  },
  // 审批/设置高级权限 - 点击保存(给用户分配权限组)
  dutySave(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Roleapi/setroleduty`,
      params
    );
  },
  // 审批/设置高级权限 - 点击左侧名称显示详情
  roleDutyDetail(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Roleapi/get_duty_list`,
      params
    );
  },
  // 审批/设置高级权限 - 新增/编辑
  saveRoleDuty(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Dutyapi/save`, params);
  },
  // 审批/设置高级权限 - 删除
  delRoleDuty(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Dutyapi/del`, params);
  },

  // 审批/审批事项类别
  getSystemTag() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/FlowTypeapi/tag_managenew`,
      "",
      false
    );
  },
  // 审批/审批事项类别 - 点击行显示详情
  readSystemTag(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/system_tag/read`, params);
  },
  // 审批/审批事项类别 - 新增/编辑
  saveSystemTag(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/SystemTag/save`, params);
  },
  // 审批/审批事项类别 - 删除
  delSystemTag(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/SystemTagapi/del`, params);
  },
};

export default SqApi;
