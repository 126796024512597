// 管理员登录接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const GsszApi = {
  // 选择部门树
  getDeptList() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/deptapi/getdplistb`, "");
  },
  // 选择部门树->点击部门查询详情
  getDeptRead(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/deptapi/read/id/${params.id}`,
      params
    );
  },
  // 设置部门->部门级别
  getDeptGrade() {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/deptapi/getdpgrade",
      "",
      false
    );
  },
  // 上级部门点击选择
  getDept() {
    return myaxios.get(
      BMDURL + "/oa/index.php?s=/deptapi/getdplist&clickId=#",
      "",
      false
    );
  },
  // 部门负责人点击添加
  selectMaster() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/deptapi/getmaster`,
      "",
      false
    );
  },
  // 新增部门
  saveDept(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Deptapi/save`, params);
  },
  // 删除部门
  delDept(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/deptapi/del`, params);
  },
  // 排班及加班信息导入/排班 - 查询
  getAllSchedule(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/getallschedule`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 排班及加班信息导入/排班 - 导出所有行
  getAllScheduleExportAll(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/getallschedule`,
      params
    );
  },
  // 排班---表格修改班务
  updateDailySchedule(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/signapi/updatedailyschedule`,
      params
    );
  },
  // 公司设置/排班及加班信息导入/排班 -> 导入班务
  scheduleUpdate(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Signapi/doscheduleupdate`,
      params
    );
  },
  // 公司设置/排班及加班信息导入/导入加班信息
  overtimeUpdate(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/attendanceapi/postovertime`,
      params
    );
  },
  // 公司设置/设置考勤规则/导入年假初始天数
  annualUpdate(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/attendanceapi/postannual`,
      params
    );
  },
  // 公司设置/设置考勤规则/设置班次 - 查询
  planList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/AttendanceTimeplan/planlista`,
      params,
      false
    );
  },
  // 公司设置/设置考勤规则/设置班次 - 添加/编辑班务
  savePlan(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/AttendanceTimeplan/${params.url_type}`,
      params
    );
  },
  // 公司设置/设置考勤规则/设置地点 - 查询
  locationList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/AttendanceConditionplan/planlista`,
      params,
      false
    );
  },
  // 公司设置/设置考勤规则/设置地点 - 添加/编辑地点
  saveLocation(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/AttendanceConditionplan/${params.url_type}`,
      params
    );
  },
  // 公司设置/设置考勤规则/设置地点 - 删除
  delLocation(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/AttendanceConditionplan/del`,
      params
    );
  },
  // 公司设置/设置考勤规则/假期规则
  holidayRules(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/HolidayRule/rulelista`,
      params
    );
  },
  // 公司设置/设置考勤规则/假期规则 - 保存
  saveHolidayRules(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/HolidayRule/edit`, params);
  },
  // 公司设置/设置考勤规则/设置节假日 - 查询
  holidayList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/holidayapi/getlist`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 公司设置/设置考勤规则/设置节假日 - 保存
  holidaySave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/holidayapi/save`, params);
  },
  // 公司设置/设置考勤规则/设置节假日 - 删除
  holidayDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/holidayapi/del`, params);
  },
  // 公司设置/设置职位Positionapi、设置审批组workgroupapi、设置权限组Roleapi、设置部门类别DeptGradeapi
  getSetList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/${params.url_type}/getlist`,
      params,
      false
    );
  },
  // 公司设置/设置职位、设置审批组、设置权限组、设置部门类别 - 新增/编辑
  saveSetList(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/${params.url_type}/save`,
      params
    );
  },
  // 公司设置/设置职位、设置审批组、设置权限组、设置部门类别 - 删除
  delSetList(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/${params.url_type}/del`,
      params
    );
  },
  // 公司设置/分配可用功能 - 获取上方、左侧、右侧数据 (nodelist&eq_pid=? 某个菜单下的所有子菜单)
  roleNodeList(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/roleapi/nodelist`, params);
  },
  // 公司设置/分配可用功能 - 点击左侧名称显示详情(某个权限组现有权限)
  roleNodeDetail(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Roleapi/get_node_list`,
      params
    );
  },
  // 公司设置/分配可用功能 - 点击保存
  roleNodeSave(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Roleapi/setrolenode`,
      params
    );
  },
  // 公司设置/公司人员分组 roleuser获得当前权限组和用户
  roleUser(params) {
    return myaxios.get(BMDURL + `/oa/index.php?s=/roleapi/roleuser`, params);
  },
  // 公司设置/公司人员分组 查权限组->点击左侧员工get_role_list 查用户->点击右侧get_roleuser_list
  roleList(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Roleapi/${params.url_type}`,
      params
    );
  },
  // 公司设置/公司人员分组 - 点击保存(给用户分配权限组)
  userRoleSave(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Roleapi/setuserrole`,
      params
    );
  },
  // 公司设置/系统通知人设置
  noticeList() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Noticeuserapi/getlist`,
      "",
      false
    );
  },
  // 公司设置/系统通知人设置-删除
  noticeDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Noticeuserapi/del`, params);
  },
  // 公司设置/系统通知人设置-新增add
  noticeSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Noticeuserapi/save`, params);
  },
  // 公司设置/设置考勤规则/设置考勤月报导出项
  reportMonthly() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/fldnameapi/getlist&tabname=reportmonthly`,
      "",
      false
    );
  },
  // 公司设置/设置考勤规则/设置考勤月报导出项 - 点击名称显示详情
  reportMonthlyRead(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Fldnameapi/read`, params);
  },
  // 公司设置/设置考勤规则/设置考勤月报导出项 - 新增add/保存edit
  reportMonthlySave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Fldnameapi/save`, params);
  },
  // 公司设置/设置考勤规则/设置考勤月报导出项 - 删除
  reportMonthlyDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Fldnameapi/del`, params);
  },
  // 公司设置/设置考勤规则/设置考勤月报导出项 - 提交选择
  reportMonthlySet(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Fldnameapi/setfld`, params);
  },

  // 公司设置/管理专员设置
  companyManage() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/companyadminapi/getmanage`);
  },
  // 公司设置/管理专员设置 点击人员的时候，需要显示他目前管理的公司
  companyList(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/get_company_list`,
      params
    );
  },
  // 公司设置/管理专员设置 取消权限
  companyCancel(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/cancelit`,
      params
    );
  },
  // 公司设置/管理专员设置 保存
  companySave(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/set_company`,
      params
    );
  },
};

export default GsszApi;
