// 专管员接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const ZgyApi = {
  // 专管员/选择分公司
  getCompanyList() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getcompanylist`,
      "",
      false
    );
  },
  // 专管员/员工信息维护
  getUserList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getuser`,
      params,
      false
    );
  },
  // 专管员/员工信息维护-点击详情
  getUserRead(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/read`,
      params
    );
  },
  // 专管员/员工信息维护-保存
  getUserSave(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/saveuser`,
      params
    );
  },
  // 专管员/员工信息维护-查看已上传
  getUserCheck() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/getcheckuser`
    );
  },
  // 专管员/工资相关参数
  getPayPlanList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payplanapi/getlist`,
      params,
      false
    );
  },
  // 专管员/工资相关参数-点击详情
  getPlanRead(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Payplanapi/read`, params);
  },
  // 专管员/工资相关参数-新增/编辑
  planSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Payplanapi/save`, params);
  },
  // 专管单位工资管理->工资参数/删除
  planDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Payplanapi/del`, params);
  },
  // 专管员/二级单位提醒人设置
  noticeUserList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getnoticeuser`,
      params
    );
  },
  // 专管员/二级单位提醒人设置-新增
  noticeSave(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/noticeusersave`,
      params
    );
  },
  // 专管员/二级单位提醒人设置-删除
  noticeDel(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/noticeuserdel`,
      params
    );
  },
  // 专管员/工资查询
  getPayroll(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getpayrolllistnew`,
      params,
      false
    );
  },
  // 专管员/工资查询-点击左侧表格行显示详情->右侧表格
  payDetail(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/payread`,
      params,
      false
    );
  },
  // 专管员/工资查询-点击详情表格显示详情->表单
  payDetailRead(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/paydetailread`,
      params
    );
  },
  // 专管员/工资查询-点击详情表格显示详情->表单-保存修改
  payDetailReadUpdate(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/updateother`,
      params
    );
  },
  // 专管员/工资查询-退回修改unlock/工资已发setfinal
  payFn(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/${params.url_type}`,
      params
    );
  },

  // 专管单位合同管理getcompanytreeb/所有派遣单位合同管理getcompanytree->选择单位树 搜索面板->管理部门mdept
  getCompanyTree(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/${params.url_type}`,
      params,
      params.showLoading
    );
  },
  // 专管单位合同管理-查询
  getExpiryListCid(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getexpirylist`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 专管员/合同管理/新增人员
  addNewPerson(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/companyadminapi/newuser`,
      params
    );
  },
  // 专管员/合同管理 -> 导出人员getexpirylist/导出合同getcontractlist
  contractExportType(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/companyadminapi/${params.url_type}`,
      params,
      true,
      "blob"
    );
  },
  // 专管员/合同管理 -> 导入人员importuserbaseb/导入合同importcontractb
  importUserContract(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/${params.url_type}`,
      params
    );
  },
  // 专管员/合同管理 -> 删除选中人员
  userDel(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Companyadminapi/deluser`,
      params
    );
  },
  // 专管员/合同管理 - 点击'续签'/合同详情 -> 续签合同add/编辑合同edit
  saveContract(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/companyadminapi/newcontract`,
      params
    );
  },
  // 合同管理--->点击'终止'/'解除' ---> 终止/解除 合同
  endContract(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/companyadminapi/contractdetail`,
      params
    );
  },
  // 专管单位工资管理->点击左侧树形某个单位
  getCompanyTreeId(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/setcurid`,
      params
    );
  },
  // 专管单位工资管理->人员信息
  getCompanyUserList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getuserlist`,
      params,
      false
    );
  },
  // 专管单位工资管理->人员信息/保险方案数据
  getPayPlan(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getpayplan`,
      params,
      false
    );
  },
  // 专管单位工资管理->人员信息/保险方案数据-保存修改
  saveUser(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/userapi/save`, params);
  },
  // 专管单位工资管理->工资参数
  getPlanList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getplanlist`,
      params,
      false
    );
  },
  // 专管单位工资管理->工资参数/新增
  addPlan(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Payplandefaultapi/save`,
      params
    );
  },
  // 专管单位工资管理->工资参数/编辑
  editPlan(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/saveplan`,
      params
    );
  },
  // 专管单位工资管理->工资参数/删除
  delPlan(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Payplandefaultapi/del`,
      params
    );
  },
  // 专管单位工资管理->应发导入及关联
  getPayList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/getpayrolllist`,
      params,
      false
    );
  },
  // 专管单位工资管理->应发导入及关联-点击显示详情
  getPayDetail(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/getpayrolldetail`,
      params,
      false
    );
  },
  // 专管单位工资管理->应发导入及关联-导入应发
  importYf(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/importpayroll`,
      params
    );
  },
  // 专管单位工资管理->应发导入及关联-应发关联
  yfSet(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/setpayroll`,
      params
    );
  },
  // 专管单位工资管理->应发导入及关联-删除
  delYf(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/delpayroll`,
      params
    );
  },
  // 专管单位工资管理->工资查询
  getPayInfo(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/getpayrollinfo`,
      params,
      false
    );
  },
  // 专管单位工资管理->工资查询-点击详情显示
  getPayInfoRead(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/payrollread`,
      params,
      false
    );
  },
  // 专管单位工资管理->工资查询-解锁
  setUnLock(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/setunlock`,
      params
    );
  },
  // 专管单位工资管理->工资查询-发放工资
  setFinal(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/setfinal2`,
      params
    );
  },
  // 专管单位工资管理->工资条导入及关联
  getGzList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/getgzlist`,
      params,
      false
    );
  },
  // 专管单位工资管理->工资条导入及关联-点击显示详情
  getGzDetail(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/getgzdetail`,
      params,
      false
    );
  },
  // 专管单位工资管理->工资条导入及关联-导入工资条
  importGzt(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/importgz`,
      params
    );
  },
  // 专管单位工资管理->工资条导入及关联-删除工资条
  delGzt(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/companyadminapi/delgz`,
      params
    );
  },
  // 专管单位工资管理->工资条导入及关联-关联
  setGzt(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/setgz`,
      params
    );
  },
  // 专管单位工资管理->工资条查询
  getGztInfo(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/getgzinfo`,
      params,
      false
    );
  },
  // 专管单位工资管理->工资条查询-点击详情显示
  getGztInfoRead(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/gzread`,
      params,
      false
    );
  },
  // 专管单位工资管理->工资条查询-发放工资条
  gztSetFinal(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Companyadminapi/gzsetfinal`,
      params
    );
  },
};

export default ZgyApi;
