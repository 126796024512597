// 考勤管理接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const KqglApi = {
  // 各部门考勤统计 - 部门列表
  signTree() {
    return myaxios.get(BMDURL + `/oa/index.php?s=/signapi/signdaysumtree`);
  },
  // 各部门考勤统计 - 点击图表显示表格数据
  chartsTable(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/getsigndaysumdept`,
      params,
      false
    );
  },
  // 各部门考勤统计 - 当日考勤统计（统计数据有效时间）导出
  exportSignDay() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/signdaysumexport`,
      "",
      true,
      "blob"
    );
  },
  // 各部门考勤统计 - 月份树
  signMonthList() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/monthlist`,
      "",
      false
    );
  },
  // 各部门考勤统计 - 月份树 - 实时统计
  signMonthStatistics(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/generatereport`,
      params,
      false
    );
  },
  // 每日出勤统计reportdailylista/每日出勤统计(临时打卡)reportdailylista56 - 查询
  signDailyList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/${params.url_type}`,
      params,
      false
    );
  },
  // 每日出勤统计signdayinfoa/每日出勤统计(临时打卡)signdayinfoa56 - 详情
  infoDailyList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/${params.url_type}`,
      params,
      params.opmode_type == "export" ? true : false //导出所有行true
    );
  },
  // 月考勤表 - 搜索面板中月份的下拉选项
  getMonthly() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/getmonthlylist`,
      "",
      false
    );
  },
  // 月考勤表 - 综合工时getattendancemonthly/标准工时getattendancemonthly1/临时签到getattendancemonthly56 - 查询
  monthlyList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/attendanceapi/${params.url_type}`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 月考勤表 - 导出所有行
  monthExportAll(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/attendanceapi/${params.url_type}`,
      params
    );
  },
  // 月考勤汇总reportmonthlysearcha - 查询
  monthlySum(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/${params.url_type}`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 月考勤汇总signinfosearcha - 详情
  monthlySumDetail(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/${params.url_type}`,
      params,
      params.showLoading == false ? false : true
    );
  },
  // 每日工作餐 - 查询
  dailyLunch(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/orderlunchapi/orderlist`,
      params,
      false
    );
  },
  // 每日工作餐详情
  dailyLunchDetail(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/orderlunchapi/orderdetail`,
      params,
      params.opmode_type == "export" ? true : false //导出所有行true
    );
  },
  // 加班信息、事前请假信息、事后请假信息 - 查询
  jbqjMsg(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/attendanceapi/${params.url_type}`,
      params,
      params.opmode_type == "export" ? true : false //导出所有行true
    );
  },
  // 所有人的年假getannul/所有人的年假（历史）getannulhistory/所有人的工时银行getworkhours - 查询
  getAnnual(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/attendanceapi/${params.url_type}`,
      params,
      false
    );
  },
  // 工作日报（所有人）worklogapi/每日健康上报（所有人）dailyreportapi - 查询
  getAllList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/${params.url_type}/getlist`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 定位信息tracereport/打卡操作记录report - 查询
  dwdkReport(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/${params.url_type}`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 定位信息tracereport/打卡操作记录report - 导出所有行
  dwdkReportExportAll(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/${params.url_type}`,
      params
    );
  },
  // 搜索面板--->部门
  deptList(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/deptapi/getdpnlista`,
      params,
      false
    );
  },
  // 本部门月打卡信息getdeptuser/打卡信息修改getalluser - 查询
  getDkxx(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signdaydetailapi/${params.url_type}`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 打卡信息中表格数据项的修改操作
  dkxxUpdateField(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/signdaydetailapi/updatefield`,
      params
    );
  },
  // 保密承诺签署情况 - 查询
  getConfidential(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/confidentialagreementapi/getlist`,
      params,
      params.opmode == "export" ? true : false,
      params.opmode == "export" ? "blob" : "json"
    );
  },
  // 个人出勤统计 - 查询
  getPersonalAttendance(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/signapi/getempsigndayinfo`,
      params
    );
  },
};

export default KqglApi;
