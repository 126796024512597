// 共享文件接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const GxwjApi = {
  // 政策、法规更新...
  sharedFiles(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/docapi/folder/fid/${params.id}&opmode=layui`,
      params
    );
  },
  // 政策、法规更新.../上传
  fileSave(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/Docapi/save`, params);
  },
  // 政策、法规更新.../删除
  fileDel(params) {
    return myaxios.post(BMDURL + `/oa/index.php?s=/docapi/removefile`, params);
  },
  // 文档搜索
  docSearch(page = 1, limit = 10, params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/docapi/getdoclist&page=${page}&limit=${limit}`,
      params,
      false
    );
  },
  // 文档管理
  docManage() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/Docapi/folder_managenew`,
      "",
      false
    );
  },
  // 文档管理-父节点
  docManageFather() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/SystemFolderapi/getparent&controller=Doc`,
      "",
      false
    );
  },
  // 文档管理-保存/删除
  docManageEdit(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/Docapi/folder_manage`,
      params
    );
  },
  // 文档管理-新增
  docManageAdd(params) {
    return myaxios.post(
      BMDURL + `/oa/index.php?s=/SystemFolderapi/save`,
      params
    );
  },
};

export default GxwjApi;
