import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import baseURL from "./http/baseURL";
Vue.config.productionTip = false;
Vue.prototype.UPLOADURL = baseURL.UPLOADURL;

//引入elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// ElementUI默认配置修改
ElementUI.Pagination.props.pagerCount.default = 5; //分页的页码按钮的数量默认改为5(只能设置5-21的奇数)
ElementUI.Select.props.popperAppendToBody.default = false; //el-select设置popperAppendToBody默认改为false
Vue.use(ElementUI);
// console.log(ElementUI);

// 引入umyui
import UmyUi from "umy-ui";
import "umy-ui/lib/theme-chalk/index.css";
Vue.use(UmyUi);

// 阿里巴巴矢量图
import "./assets/iconfont/iconfont.js"; //引入阿里巴巴图标库js
import "./assets/iconfont/iconfont.css"; //引入阿里巴巴图标库css

// echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
import ElementResizeDetectorMaker from "element-resize-detector";
Vue.prototype.$erd = ElementResizeDetectorMaker();

//引入
import moment from "moment";
//挂载到原型
Vue.prototype.$moment = moment;
//汉化，否则显示日期格式是国外的日期格式
moment.locale("zh-CN");

// // 打印
// import Print from "vue-print-nb";
// Vue.use(Print);
// import Print from "./assets/print";
// Vue.use(Print);

// 手写签名
import vueEsign from "vue-esign";
Vue.use(vueEsign);

// video
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
Vue.use(VideoPlayer);

// video 水印
// import "videojs-dynamic-watermark";

// 全局点击防抖处理
const on = Vue.prototype.$on;
Vue.prototype.$on = function (e, func) {
  // console.log(e);
  // 目前只有点击需要防抖
  if (e === "click") {
    let sto;
    on.call(this, e, function () {
      sto ? clearTimeout(sto) : func.apply(this, arguments);
      sto = setTimeout(() => (sto = undefined), 500);
      // 点击后移除焦点
      this.$el && this.$el.blur();
    });
  } else {
    on.call(this, e, func);
  }
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
