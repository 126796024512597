// 封装请求前缀
const URL_ENV = {
  DEV: {
    BMDURL: location.protocol == "https:" ? "/api" : "/httpApi",
    UPLOADURL: "",
  },
  PRO: {
    BMDURL: "",
    UPLOADURL: "",
  },
};

// console.log(URL_ENV, location);

export default URL_ENV.DEV;
