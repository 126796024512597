// 岗位晋升考试接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const KsApi = {
  // 考试基本信息接口 --- 添加
  // &title=考试名称&opmode=add&type=提高&prev=前序培训id
  addExamInfo({ title, type, prev = "" }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examinfoapi/save&title=${title}&opmode=add&type=${type}&prev=${prev}`,
      ""
    );
  },
  // 考试基本信息接口 --- 编辑修改
  editExamInfo({ id, title, type, prev = "" }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examinfoapi/save&id=${id}&title=${title}&opmode=edit&type=${type}&prev=${prev}`,
      ""
    );
  },
  // 考试基本信息接口 --- 查询
  getExamInfoList() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examinfoapi/getlist`,
      "",
      false
    );
  },
  // 考试基本信息接口 --- 删除
  delExamInfo({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examinfoapi/del&id=${id}`,
      "",
      false
    );
  },
  // 考试基本信息接口 --- 查询某一个考试详情
  getExamInfoList_id({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examinfoapi/getlist&eq_id=${id}`,
      ""
    );
  },
  // 考试题目接口 --- 添加
  // &tid=考试id&opmode=add&seqno=题号&qtype=题型&qcontent=题干&qanswer=答案
  addExamTopic({ tid, seqno, qtype, qcontent, qanswer, qscore }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examtopicapi/save&tid=${tid}&opmode=add&seqno=${seqno}&qtype=${qtype}&qcontent=${qcontent}&qanswer=${qanswer}&qscore=${qscore}`,
      ""
    );
  },
  // 考试题目接口 --- 编辑修改
  // &id=题目id&opmode=edit&seqno=题号&qtype=题型&qcontent=题干&qanswer=答案 qscore分值
  editExamTopic({ id, seqno, qtype, qcontent, qanswer, qscore }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examtopicapi/save&id=${id}&opmode=edit&seqno=${seqno}&qtype=${qtype}&qcontent=${qcontent}&qanswer=${qanswer}&qscore=${qscore}`,
      ""
    );
  },
  // 考试题目接口 --- 查询
  // eq_tid=考试id eq_qtype=题型
  getExamTopicList({ eq_tid, eq_qtype = "", showLoading }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examtopicapi/getlist&eq_tid=${eq_tid}&eq_qtype=${eq_qtype}&limit=9999`,
      "",
      showLoading
    );
  },
  // 考试题目接口 --- 删除
  delExamTopic({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examtopicapi/del&id=${id}`,
      ""
    );
  },
  // 考试题选项接口 --- 添加
  // &tid=考试id&qid=题目id&opmode=add&qno=选项序号&qoption=选项
  addExamDetail({ tid, qid, qno, qoption }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examdetailapi/save&tid=${tid}&qid=${qid}&opmode=add&qno=${qno}&qoption=${
          qoption.indexOf("+") != -1 ? qoption.replace(/\+/g, "%2B") : qoption
        }`,
      "",
      false
    );
  },
  // 考试题选项接口 --- 编辑修改
  // &id=选项id&opmode=edit&tid=考试id&qid=题目id&qno=选项序号&qoption=选项
  editExamDetail({ id, tid, qid, qno, qoption }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examdetailapi/save&id=${id}&opmode=edit&tid=${tid}&qid=${qid}&qno=${qno}&qoption=${
          qoption.indexOf("+") != -1 ? qoption.replace(/\+/g, "%2B") : qoption
        }`,
      "",
      false
    );
  },
  // 考试题选项接口 --- 查询
  // eq_tid=考试id eq_qid=题目id
  getExamDetailList({ eq_tid, eq_qid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examdetailapi/getlist&eq_tid=${eq_tid}&eq_qid=${eq_qid}`,
      "",
      false
    );
  },
  // 考试题选项接口 --- 删除
  delExamDetail({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examdetailapi/del&id=${id}`,
      ""
    );
  },
  // 考试日志接口 --- 添加 (考试日志记录每次考试的每道题的答案和得分)
  //  &tid=考试id&opmode=add&qid=题目id &starttime=开始时间&endtime=结束时间&evtlength=时长（秒）&myanswer =用户答案 myscore,对应每道题的得分
  // 增加一个token，同一个人同一次考试的所有题，token是一个，建议用时间，整数
  addExamLog({
    tid,
    qid,
    starttime,
    endtime,
    evtlength,
    myanswer,
    myscore,
    token,
  }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examlogapi/save&tid=${tid}&qid=${qid}&opmode=add&starttime=${starttime}&endtime=${endtime}&evtlength=${evtlength}&myanswer=${myanswer}&myscore=${myscore}&token=${token}`,
      "",
      false
    );
  },
  // 考试状态接口 --- 添加 (考试状态记录一个人最终的考试状态)
  // &tid=考试id&opmode=add&qid=题目id&score=得分&status= 0未通过 1通过 status是表示是否完成答题
  addStatus({ tid, qid, score, status, starttime, endtime }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examstatusapi/save&tid=${tid}&qid=${qid}&opmode=add&score=${score}&status=${status}&starttime=${starttime}&endtime=${endtime}`,
      "",
      false
    );
  },
  // 考试状态接口 --- 编辑修改
  // &id=状态记录id&opmode=edit &qid=题目id&score=得分&status=0未通过 1通过
  editStatus({ id, qid, score, status, starttime, endtime }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examstatusapi/save&id=${id}&qid=${qid}&opmode=edit&score=${score}&status=${status}&starttime=${starttime}&endtime=${endtime}`,
      "",
      false
    );
  },
  // 考试状态接口 --- 查询
  // &eq_tid=考试id&eq_qid=题目id
  getExamStatus({ eq_tid, eq_qid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/examstatusapi/getlist&eq_tid=${eq_tid}&eq_qid=${eq_qid}`,
      "",
      false
    );
  },

  // 考试统计页接口--问卷题目--- 查询某一个题
  getExamQid({ eq_qid }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examdetailapi/getlist&eq_qid=${eq_qid}`,
      "",
      false
    );
  },
  // 考试统计页--查询某个情况
  getExamList_id(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examstatusapi/getsumlist`,
      params,
      false
    );
  },
  // 考试统计页--日志查询
  getExamLogList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examlogapi/getsumlist`,
      params,
      false
    );
  },
  // 考试统计页--你想要的总分的最大值，可以用getlist接口，得到完整的数据，然后自己去算
  // 查看考试详情时，用上面的接口
  // 其中的token用来区分每一次考试，token相同的，是同一次考试
  // 提交新的考试的时候，也需要增加token参数，一般用时间就可以，整型
  examLogGetList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examlogapi/getlist`,
      params,
      false
    );
  },
  examLogGetSumList2(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/examlogapi/getsumlist2`,
      params,
      false
    );
  },
};

export default KsApi;
